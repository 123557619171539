export default {
  //productiom
  BASE_URL: "https://api.ibidz.in/",
  BASE_URL_IMG: "https://admin.ibidz.in/",
  // dev
  //  BASE_URL: "https://wpr.intertoons.net/ibidzapi/",
  //  BASE_URL_IMG: "https://wpr.intertoons.net/ibidzadmin/",
  //  BASE_URLASSETS: "https://wpr.intertoons.net/ibidz/",

   PAYMENT_GT: "https://payments.ibidz.in",

  curency: "INR",
  countryCode: "en-IN",
  minFractionDigits: 0,
  RefreshAuctionPage: 60000, // 1000 = 1 sec
  pagelaoddelay: 2, //  1 = 1  sec
  pageRedirectDelay: 3, // 1 = 1 sec
  toastDelay: 5, // 1 = 1 sec
  rpayKey: "rzp_test_cWQhGghXCxe6cO",
  //CurrNttimezone: "Asia/Dubai",
  CurrNttimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,

  copyright:
    " Developed by Intertoons" + 
    "",
  copyrighturl: "https://intertoons.com/",
};
